<template>
  <div>
    <button class="btn" @click="fullScreen">
      {{isFullscreen?'退出全屏':'进入全屏'}}
    </button>
    <div class="game-con  flex">
      <audio
        ref="instruction"
        src="@/assets/audio/game3/instruction.mp3"
      ></audio>
      <audio
        ref="rotate"
        loop="loop"
        src="@/assets/audio/game3/rotate.mp3"
      ></audio>

      <!-- 左侧 -->
      <div class="game-l">
        <div class="between">
          <div class="game-title"></div>
          <div class="game-instruction" @click="showMask"></div>
        </div>
        <div class="disc">
          <img
            :class="{ rotate: isPlay, pause: isPaused }"
            src="@/assets/img/game3/022.png"
            alt=""
          />
        </div>
        <button class="play" v-if="isPlayBtn" @click="handleStartEnd"></button>
        <button class="stop" v-else @click="handlePause"></button>
      </div>
      <!-- 右侧内容 -->
      <div class="game-r">
        <li>
          <img class="sign" src="@/assets/img/game3/1.png" alt="" />
          <div>
            <img class="item-pic" :src="preSrc + items[0]" alt="" />
          </div>
        </li>
        <li>
          <img class="sign" src="@/assets/img/game3/2.png" alt="" />
          <div>
            <img
              v-if="items[1]"
              class="item-pic"
              :src="preSrc + items[1]"
              alt=""
            />
          </div>
        </li>
        <li>
          <img class="sign" src="@/assets/img/game3/3.png" alt="" />
          <div>
            <img
              v-if="items[2]"
              class="item-pic"
              :src="preSrc + items[2]"
              alt=""
            />
          </div>
        </li>
        <li class="item-4">
          <img class="sign" src="@/assets/img/game3/4.png" alt="" />
          <div>
            <img
              v-if="items[3]"
              class="item-pic"
              :src="preSrc + items[3]"
              alt=""
            />
          </div>
        </li>
        <li class="item-5">
          <img class="sign" src="@/assets/img/game3/5.png" alt="" />
          <div>
            <img
              v-if="items[4]"
              class="item-pic"
              :src="preSrc + items[4]"
              alt=""
            />
          </div>
        </li>
        <li class="item-6">
          <img class="sign" src="@/assets/img/game3/6.png" alt="" />
          <div>
            <img
              v-if="items[5]"
              class="item-pic"
              :src="preSrc + items[5]"
              alt=""
            />
          </div>
        </li>
        <li class="item-7">
          <img class="sign" src="@/assets/img/game3/7.png" alt="" />
          <div>
            <img
              v-if="items[6]"
              class="item-pic"
              :src="preSrc + items[6]"
              alt=""
            />
          </div>
        </li>
        <li class="item-8">
          <img class="sign" src="@/assets/img/game3/8.png" alt="" />
          <div>
            <img
              v-if="items[7]"
              class="item-pic"
              :src="preSrc + items[7]"
              alt=""
            />
          </div>
        </li>
      </div>
      <!-- 弹窗begin -->
      <transition name="fade">
        <div class="mask-container" v-if="isShowMask">
          <div class="mask-bg" @click="hideMask"></div>
          <div
            class="mask-con"
            :style="{
              background:
                'url(' + preSrc + instructionPic + ')center center no-repeat',
            }"
          >
          </div>
        </div>
      </transition>
      <!-- 弹窗end -->
    </div>
  </div>
</template>
<script>
import screenfull from "screenfull";
export default {
  data() {
    return {
      preSrc: config.src,
      isPlay: false,
      isPaused: true,
      deg: 0,
      isPlayBtn: true,
      items: [],
      isShowMask: false, //是否展示弹窗
      instructionPic: "", //游戏提示图片
      isFullscreen: false,
    };
  },
  created() {
    // 如果有configId就是用户创建的游戏
    let _self = this;
    // localStorage.setItem("originPath", this.$route.path);
    let configId = _self.$route.params.configId;
    let gameId = _self.$route.params.gameId;
    let title = _self.$route.params.title;
    title ? (document.title = title) : "";
    if (configId) {
      _self.initData(configId);
      return;
    } else {
      _self.getInfo(gameId);
    }
  },
  methods: {
    // 全屏
    fullScreen() {
      this.isFullscreen = !this.isFullscreen;
      // 如果不允许进入全屏，发出不允许提示
      if (!screenfull.isEnabled) {
        this.$message({
          message: "您的浏览器不支持全屏",
          type: "warning",
        });
        return false;
      }
      screenfull.toggle();
    },
    // 获取游戏详情
    getInfo(id) {
      let _self = this;
      _self.axios
        .post(config.url.gameInfo, _self.$qs.stringify({ game_id: id }))
        .then((res) => {
          if (res.code == 200) {
            _self.instructionPic = res.data.instruction;
            _self.items = res.data.pic;
          }
        });
    },
    initData(id) {
      let _self = this;
      _self.axios
        .post(config.url.gamePics, _self.$qs.stringify({ config_id: id }))
        .then((res) => {
          if (res.code == 200) {
            _self.instructionPic = res.data.instruction;
            if (res.data.config.length <= 0) {
              return;
            }
            _self.items = res.data.config;
          }
        });
    },
    //展示弹窗
    showMask() {
      let _self = this;
      _self.$refs.instruction.play();
      _self.isShowMask = true;
    },
    // 隐藏弹窗
    hideMask() {
      let _self = this;
      _self.$refs.instruction.play();
      _self.isShowMask = false;
    },
    // 转动转盘
    handleStartEnd() {
      let _self = this;
      _self.$refs.instruction.play();
      setTimeout(() => {
        _self.$refs.rotate.play();
      }, 2);

      _self.isPlay = true;
      _self.isPaused = false;
      _self.isPlayBtn = false;
    },
    // 停止转盘
    handlePause() {
      let _self = this;
      _self.$refs.instruction.play();
      _self.$refs.rotate.pause();
      _self.isPaused = true;
      _self.isPlayBtn = true;
    },
  },
  mounted() {
    if (screenfull.isEnabled) {
      screenfull.on("change", () => {
        this.isFullscreen = screenfull.isFullscreen;
      });
    }
  },
};
</script>
<style scoped>
.line {
  width: 100%;
  height: 60px;
}
.btn {
  margin: 60px auto 0;
}
.game-instruction:hover,
.play:hover,
.stop:hover {
  cursor: pointer;
}
.game-con {
  width: 1280px;
  height: 720px;
  max-width: 1280px;
  margin: 30px auto 0;
  padding: 20px;
  background: rgba(255, 166, 173);
  border: 4px solid #333;
  border-radius: 10px;
  box-sizing: border-box;
}
.game-l {
  width: 430px;
  margin-right: 50px;
  background: rgba(231, 106, 104);
  border-radius: 20px;
  height: 100%;
  padding: 30px 20px;
  box-sizing: border-box;
}
.game-title {
  width: 215px;
  height: 64px;
  background: url("../../assets/img/game3/title.png") center center no-repeat;
  background-size: 100%;
  /* 解决缩小图片后，图片变模糊 */
  image-rendering: -moz-crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

.disc {
  width: 368px;
  height: 368px;
  background: url("../../assets/img/game3/011.png") center center no-repeat;
  background-size: 100%;
  position: relative;
  margin: 40px auto;
}
.disc img {
  width: 368px;
  height: 368px;
  object-fit: cover;
  border-radius: 50%;
}
.play {
  width: 200px;
  height: 120px;
  background: url("../../assets/img/game3/start.png") center center no-repeat;
  background-size: 100%;
  margin-left: 90px;
}
.stop {
  width: 200px;
  height: 120px;
  background: url("../../assets/img/game3/stop.png") center center no-repeat;
  background-size: 100%;
  margin-left: 90px;
}
/* 右 */
.game-r {
  width: 780px;
  height: 100%;
  background: rgba(231, 106, 104);
  border-radius: 20px;
  padding: 10px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.game-r li {
  width: 210px;
  height: 210px;
  background: rgba(255, 166, 173);
  border-radius: 10px;
  position: relative;
}
.game-r li div {
  width: 190px;
  height: 190px;
  background: #fff;
  border-radius: 10px;
  margin: 10px auto;
}

.game-r .sign {
  position: absolute;
  top: -10px;
  left: -20px;
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  /* 解决缩小图片后，图片变模糊 */
  image-rendering: -moz-crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

.item-4 {
  top: 5px;
  left: 507px;
}
.item-5 {
  top: 225px;
  left: 254px;
}
.item-6 {
  top: 225px;
  left: -256px;
}
.item-7 {
  left: -65px;
  top: 6px;
}
.item-8 {
  top: -214px;
  left: -445px;
}
/* 转盘动效 */
@keyframes rotateDeg {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.rotate {
  animation: rotateDeg 0.5s linear infinite;
}
.pause {
  animation-play-state: paused;
}
.item-pic {
  position: relative;
  top: 21px;
  left: 16px;
  width: 154px;
  height: 148px;
  object-fit: contain;
  padding: 10px;
  box-sizing: border-box;
}
/*  */
.mask-con {
  width: 738px;
  height: 542px;
  background-size: 100%;
  padding: 150px 80px 55px;
  box-sizing: border-box;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.mask-con .title {
  text-align: center;
  margin-bottom: 10px;
  font-size: 28px;
}
.mask-con p {
  font-size: 24px;
  line-height: 42px;
  text-align: left;
}
</style>
